import { formatDate } from 'fullcalendar';
import { renderCellExpand } from 'components/Common/GridCell/GridCellExpand';
import { renderReportToolExecutionAction } from './utils/reportToolComponentUtils';

export const reportExecutionColumns = (
  handleDownloadReportExecution,
  handleViewReportExecution
) => [
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 80,
    renderCell: (params) =>
      renderReportToolExecutionAction(
        params,
        handleDownloadReportExecution,
        handleViewReportExecution
      ),
  },
  { headerName: 'User Name', field: 'username', width: 250 },
  {
    headerName: 'Report',
    field: 'report',
    renderCell: renderCellExpand,
    width: 250,
  },
  {
    headerName: 'Description',
    field: 'description',
    renderCell: renderCellExpand,
    width: 350,
  },
  { headerName: 'Status', field: 'status', type: 'date', width: 150 },
  {
    headerName: 'Status Msg',
    field: 'statusMsg',
    type: 'date',
    width: 150,
    renderCell: renderCellExpand,
  },
  { headerName: 'Created At', field: 'createdAt', type: 'date', width: 150 },
  { headerName: 'Started At', field: 'startedAt', type: 'date', width: 250 },
  { headerName: 'Finished At', field: 'finishedAt', type: 'date', width: 150 },
  { headerName: 'Output Type', field: 'outputType', type: 'date', width: 150 },
  {
    headerName: 'Semester',
    field: 'semester',
    width: 150,
    renderCell: renderCellExpand,
  },
  {
    headerName: 'Student Ids',
    field: 'studentIds',
    width: 150,
    renderCell: renderCellExpand,
  },
];

export const getReportExecutionListData = (reportExecutionListData) => {
  const items = reportExecutionListData?.items;
  if (!items || items.length === 0) {
    return [];
  }
  return items.map(
    ({
      id,
      username,
      description,
      report,
      status,
      statusMsg,
      createdAt,
      startedAt,
      finishedAt,
      outputType,
      reportParams,
      metadata,
    }) => ({
      id,
      username,
      description,
      report,
      status,
      statusMsg,
      createdAt: formatDate(createdAt),
      startedAt: formatDate(startedAt),
      finishedAt: formatDate(finishedAt),
      outputType,
      semester: reportParams?.semester,
      studentIds: reportParams?.studentIds,
      metadata,
    })
  );
};
