import React, { useEffect } from 'react';
import MDBox from 'components/MDBox';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import { GridForm } from 'components/Ui/styled';
import { useDispatch, useSelector } from 'react-redux';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { resetReportExecution } from 'layouts/reportTool/store/reportExecutionSlice/reportExecutionSlice';
import {
  formatDate,
  getDialogHeading,
  showBackdropLoader,
} from 'utils/commonUtils';
import { PaperPropsWithoutScrollbar } from 'constants';

const ViewReportExecutionDialog = (props) => {
  const { open, setOpen, viewDialogLoading } = props;
  const dispatch = useDispatch();

  const reportExecutionForm = useSelector(
    (state) => state?.reportExecutionForm
  );

  const {
    report,
    username,
    description,
    createdAt,
    startedAt,
    finishedAt,
    status,
    outputType,
    statusMsg,
    metadata,
    reportParams = {},
  } = reportExecutionForm || {};

  const { semester, studentIds } = reportParams || {};

  const defaultValue = {
    report: report,
    username: username,
    description: description,
    createdAt: formatDate(createdAt),
    startedAt: formatDate(startedAt),
    finishedAt: formatDate(finishedAt),
    semester: semester,
    studentIds: studentIds,
    status: status,
    outputType: outputType,
    statusMsg: statusMsg,
    metadata: metadata,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { reset, control } = useFunction;

  const handleClose = () => {
    setOpen(false);
    dispatch(resetReportExecution());
  };

  useEffect(() => {
    reset(defaultValue);
  }, [reportExecutionForm, reset]);

  return (
    <>
      {showBackdropLoader(viewDialogLoading)}
      <Dialog open={open} PaperProps={PaperPropsWithoutScrollbar}>
        {getDialogHeading('Report Execution', handleClose)}
        <GridForm container spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormInputText
              name="report"
              type="text"
              label="Report"
              control={control}
              readOnly
            />
            <FormInputText
              name="username"
              type="text"
              label="User Name"
              control={control}
              readOnly
            />

            <FormInputText
              name="createdAt"
              type="text"
              label="Created At"
              control={control}
              readOnly
            />
            <FormInputText
              name="startedAt"
              type="text"
              label="Started At"
              control={control}
              readOnly
            />
            <FormInputText
              name="finishedAt"
              type="text"
              label="Finished At"
              control={control}
              readOnly
            />
            <FormInputText
              name="description"
              type="text"
              label="Description"
              multiline={true}
              rows={2}
              control={control}
              readOnly
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputText
              name="semester"
              type="text"
              label="Semester"
              control={control}
              readOnly
            />
            <FormInputText
              name="studentIds"
              type="text"
              label="Student Id's"
              control={control}
              readOnly
            />
            <FormInputText
              name="status"
              type="text"
              label="Status"
              control={control}
              readOnly
            />
            <FormInputText
              name="outputType"
              type="text"
              label="Output Type"
              control={control}
              readOnly
            />
            <FormInputText
              name="statusMsg"
              type="text"
              label="Status Message"
              control={control}
              readOnly
            />
          </Grid>
        </GridForm>
        <MDBox mb={3}></MDBox>
      </Dialog>
    </>
  );
};

export default ViewReportExecutionDialog;
