import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import { GridForm, GridButtonWrapper } from 'components/Ui/styled';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getDialogHeading,
  useNotification,
  renderSaveCancelButtons,
  showBackdropLoader,
  dropDownValidation,
  dropDownOptions,
  emptyFunction,
  onSave,
} from 'utils/commonUtils';
import { PaperPropsCheck, reportExecutionSuccess } from 'constants';
import { useDispatch } from 'react-redux';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import ActiveStudentsReportFields from './ActiveStudentsReportFields';
import StudentAttendanceRecordFields from './StudentAttendanceRecordFields';
import {
  getAvailableReport,
  postReportExecution,
} from 'layouts/reportTool/services/reportToolApi';

const schema = yup.object().shape({
  availableReports: dropDownValidation,
});

const ReportExecutionDialog = (props) => {
  const dispatch = useDispatch();
  const {
    open,
    setOpen,
    isFormLoading,
    availableReportList,
    fetchReportExecutions,
  } = props;
  const {
    setOpenNotification,
    setNotificationMessage,
    handleErrorResponse,
    NotificationPopup,
  } = useNotification();

  const [studentTypes, setStudentTypes] = useState([]);
  const [availableReport, setAvailableReport] = useState(null);
  const [availableReports, setAvailableReports] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);

  const defaultValue = {
    availableReports: availableReports,
    description: '',
    studentTypes: studentTypes,
    includeSchedule: false,
    includeDashboardPassword: false,
    semester: '',
    studentIds: '',
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, control, formState, register } = useFunction;
  const { errors } = formState;

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.availableReports,
    defaultValue.description,
    defaultValue.studentTypes,
    defaultValue.includeSchedule,
    defaultValue.includeDashboardPassword,
    defaultValue.semester,
    defaultValue.studentIds,
  ]);

  const handleClose = () => {
    setOpen(false);
    setStudentTypes([]);
    setAvailableReports(null);
    reset(defaultValue);
  };

  const handleStudentTypesChange = (event) => {
    const value = event.target.value;
    setStudentTypes(Array.isArray(value) ? value : value.split(','));
  };

  const handleAvailableReportsChange = async (e) => {
    setAvailableReports(e.target.value);
    setStudentTypes([]);
    try {
      const availableReport = await getAvailableReport(e.target.value);
      setAvailableReport(availableReport);
    } catch (error) {
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const onSubmit = async (data) => {
    const {
      availableReports,
      description,
      studentTypes,
      includeSchedule,
      includeDashboardPassword,
      semester,
      studentIds,
    } = data;

    const activeStudentsReportPayload = {
      report: availableReports,
      description,
      reportParams: {
        studentTypes,
        includeSchedule,
        includeDashboardPassword,
      },
    };

    const studentAttendanceRecordsPayload = {
      report: availableReports,
      description,
      reportParams: {
        semester,
        studentIds,
      },
    };

    const myPayload =
      availableReports === 'active_students_report'
        ? activeStudentsReportPayload
        : studentAttendanceRecordsPayload;

    onSave(
      reportExecutionSuccess,
      true,
      postReportExecution,
      emptyFunction,
      myPayload,
      '',
      fetchReportExecutions,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton
    );
  };

  return (
    <>
      {showBackdropLoader(isFormLoading)}
      <Dialog open={open} PaperProps={PaperPropsCheck}>
        {getDialogHeading('Report Execution', handleClose)}
        <GridForm container spacing={2}>
          <Grid item xs={12}>
            <FormInputDropdown
              name="availableReports"
              control={control}
              options={dropDownOptions(
                availableReportList,
                'identifier',
                'name'
              )}
              label="Available Reports"
              helperText={errors?.availableReports?.message}
              errors={!!errors.availableReports}
              register={register('availableReports', {
                onChange: (e) => {
                  handleAvailableReportsChange(e);
                },
              })}
            />
            <FormInputText
              name="description"
              type="text"
              label="Description"
              control={control}
            />
          </Grid>
          {availableReports === 'active_students_report' && (
            <ActiveStudentsReportFields
              control={control}
              register={register}
              defaultValue={defaultValue}
              handleStudentTypesChange={handleStudentTypesChange}
              availableReport={availableReport}
            />
          )}
          {availableReports === 'student_attendance_records' && (
            <StudentAttendanceRecordFields
              control={control}
              defaultValue={defaultValue}
              errors={errors}
              availableReport={availableReport}
            />
          )}
        </GridForm>
        <GridButtonWrapper container>
          {renderSaveCancelButtons(
            handleClose,
            handleSubmit(onSubmit),
            loadingButton
          )}
        </GridButtonWrapper>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default ReportExecutionDialog;
